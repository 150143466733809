<template>
    <router-view v-slot="{ Component }">
        <!-- <keep-alive> -->
        <component
            v-if="isAdministrator"
            :is="Component"
        />
        <!-- </keep-alive> -->
    </router-view>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed } from 'vue';
    import { useStore } from 'vuex';

    // <!-- UTILITIES -->
    import { Maybe } from 'true-myth/dist/maybe';

    // <!-- TYPES -->
    import { ECNBState } from '@/store/types/ECNBStore';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Admin',
        setup(props, context) {
            /** @param {import('vuex').Store<ECNBState>} store */
            const useUser = (store) => {
                const user = computed(() => store.state.users.me);
                return user;
            };

            /** @param {import('vuex').Store<ECNBState>} store */
            const useCurrentOrganization = (store) => {
                const organization = computed(
                    () => store.state.accounts.organization
                );
                return organization;
            };

            /**
             * @param {Object} param
             * @param {Vue.Ref<User.Model>} param.user
             * @param {Vue.Ref<Organization.Model>} param.organization
             **/
            const useAdministrator = ({ user, organization }) => {
                const isAdministrator = computed(() => {
                    const isSuper = Maybe.of(user.value?.isSuperUser).unwrapOr(
                        false
                    );
                    const role = Maybe.of(organization.value?.access?.userRole);
                    return isSuper || role.unwrapOr('guest') === 'admin';
                });
                return isAdministrator;
            };

            // ==== COMPOSABLES ====
            const store = useStore();
            const user = useUser(store);
            const organization = useCurrentOrganization(store);
            const isAdministrator = useAdministrator({ user, organization });

            return {
                isAdministrator,
            };
        },
    });
</script>
