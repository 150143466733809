<template>
    <div
        v-if="!!source"
        :class="[isLoading ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="dirtyUser"
            #default="context"
            :actions="false"
            :config="config"
        >
            <FormKit
                type="text"
                name="username"
                label="* Username"
                validation="required"
                :disabled="!isEditing"
            />
            <FormKit
                type="text"
                name="email"
                label="* Email"
                validation="required:trim|email"
                :disabled="!isEditing"
            />
            <FormKit
                type="radio"
                name="userRole"
                label="* Access Type"
                validation="required"
                :disabled="!isEditing"
                :options="options"
                :config="{
                    // config override applies to all nested FormKit components
                    classes: {
                        outer: 'mb-5 mt-3 user-access-radio-list',
                        wrapper: 'flex mt-2',
                        label: 'mx-3',
                    },
                }"
            >
                <template #label="{ option }">
                    <div class="mr-0.5 mt-0.5">
                        <span class="ml-1">{{ option.label }}</span>
                    </div>
                    <BaseTooltip
                        class="inline-block mr-4"
                        target="#tooltip-target"
                        :text="option.text"
                    >
                        <template #icon>
                            <InformationCircleIcon
                                :class="[
                                    'relative inline-block',
                                    'h-6 w-6 sm:h-4 sm:w-4',
                                    'bg-transparent hover:bg-info hover:bg-opacity-10',
                                    'text-primary-700 hover:text-info',
                                    'transform scale-100 hover:scale-125',
                                    'transition-all duration-300 ease-in-out',
                                    'rounded-lg',
                                ]"
                                aria-hidden="true"
                            />
                        </template>
                    </BaseTooltip>
                </template>
            </FormKit>
            <LoadingWrapper :isLoading="areAccountsLoading">
                <FormSection
                    class="pt-4"
                    title="Select Accounts"
                    description="Assign a user access to accounts by dragging and arranging the desired accounts into the Selected Accounts box. Hover over the dotted lines to the left of the account name to drag an account."
                    v-show="isAccountSelectionVisible"
                    :grid="[
                        'grid',
                        'grid-cols-1',
                        'mt-6',
                        'mb-6',
                        'gap-y-4',
                        'lg:grid-cols-2',
                        'max-w-3xl',
                    ]"
                >
                    <div>
                        <AgGridVue
                            id="selectableAccountsGrid"
                            class="ag-theme-alpine"
                            style="width: 300px; height: 400px"
                            :columnDefs="leftColDefs"
                            :defaultColDef="defaultColDef"
                            :rowData="leftRowData"
                            :rowDragManaged="true"
                            :suppressMoveWhenRowDragging="true"
                            :animateRows="true"
                            :getRowNodeId="rowNodeIdGetter"
                            @grid-ready="onGridReady($event, 'Left')"
                        />
                    </div>
                    <div>
                        <AgGridVue
                            id="selectedAccountsGrid"
                            class="ag-theme-alpine"
                            style="width: 300px; height: 400px"
                            :columnDefs="rightColDefs"
                            :defaultColDef="defaultColDef"
                            :rowData="rightRowData"
                            :rowDragManaged="true"
                            :getRowNodeId="rowNodeIdGetter"
                            :suppressMoveWhenRowDragging="true"
                            :animateRows="true"
                            @grid-ready="onGridReady($event, 'Right')"
                        ></AgGridVue>
                    </div>
                </FormSection>
            </LoadingWrapper>
            <p class="text-gray-400 text-sm">* indicates a required field</p>
            <LoadingWrapper
                v-if="isEditing"
                :isLoading="isSaving"
            >
                <div class="flex flex-row-reverse pt-5">
                    <ModalButton
                        v-if="isEditing"
                        theme="primary"
                        label="Save"
                        @click="onClickSave(context.state)"
                        :disabled="isLoading"
                    />
                    <ModalButton
                        theme="white"
                        label="Cancel"
                        @click="onClickCancel"
                    />
                </div>
            </LoadingWrapper>
        </FormKit>
        <div
            v-if="!!debug"
            class="mt-6"
        >
            <DebugFrame
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        ref,
        reactive,
        inject,
        onBeforeMount,
    } from 'vue';
    import { computedEager } from '@vueuse/core';
    import users from '@/api/v2/users';

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import pick from 'just-pick';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import { AgGridVue } from 'ag-grid-vue3';
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';
    import { InformationCircleIcon } from '@heroicons/vue/outline';

    // <!-- TYPES -->
    /** @typedef {import('@formkit/core').FormKitNode} FormKitNode */
    /** @typedef {ReturnType<import('./../hooks/useUserManager').useUserManager>} UserManager */

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useUserManager } from '~UserManager/hooks/useUserManager';
    import { syncRefs } from '@vueuse/shared';
    import { Maybe } from 'true-myth/dist/maybe';
    import { onUnmounted } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UserFields',
        components: {
            ModalButton,
            FormSection,
            AgGridVue,
            LoadingWrapper,
            DebugFrame,
            BaseTooltip,
            InformationCircleIcon,
        },
        props: {
            source: {
                /** @type {Vue.PropType<Omit<globalThis.User.Target, 'id'>>} */
                type: Object,
            },
            isEditing: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['submit', 'cancel'],
        setup(props, { emit }) {
            // ==== DEPENDENCY INJECTION ====
            const manager = inject('UserManager', useUserManager, true);

            // ==== COMPOSABLE ====
            const { state, constants, methods: _ } = manager;

            // ==== STATE ====
            const defaultColDef = { ...constants.DefaultColumnDefinition };
            const {
                accounts,
                leftColDefs,
                rightColDefs,
                leftRowData,
                rightRowData,
                leftGridApi,
                rightGridApi,
                areAccountsLoading,
            } = state;

            /** Provides radio button options. */
            const options = ref([
                {
                    value: 'admin',
                    label: 'Admin',
                    text: 'An Admin has full access to all accounts in an organization’s subscription. Admins can manage users - add, edit, delete, and reset passwords. Enterprise Admins can manage accounts – add, edit, and delete.',
                },
                {
                    value: 'data-manager',
                    label: 'Data Manager',
                    text: 'A Data Manager can create, upload to, edit, and delete locations.',
                },
                {
                    value: 'data-analyst',
                    label: 'Data Analyst',
                    text: 'A Data Analyst can view and analyze data, but cannot create, upload to, edit, or delete locations.',
                },
            ]);

            // ==== STATE ====

            /** @type {Vue.Ref<Partial<globalThis.User.Target>>} */
            const dirtyUser = ref(null);

            /** @type {Vue.ComputedRef<Boolean>} Are the select account inputs visible? */
            const isAccountSelectionVisible = computed(() => {
                return dirtyUser.value.userRole !== 'admin';
            });

            /** Computed ref for selected rows. */
            const selectedRows = computed(() => {
                const user = dirtyUser.value;
                const rows = user.accounts.map((a) => ({
                    id: a.id,
                    name: a.name,
                }));
                return rows;
            });

            /** Computed ref for selected ids. */
            const selectedIds = computed(() => {
                const rows = selectedRows.value;
                const ids = rows.map((r) => r.id);
                return ids;
            });

            const config = reactive({
                delay: 250,
                validationVisibility: 'blur',
            });

            // ==== METHODS ====

            /** @type {AgGrid.GetRowNodeIdFunc} */
            const rowNodeIdGetter = (data) => {
                return data?.id;
            };

            /**
             * Get the initial form state.
             */
            const initialState = () => {
                /** @type {Partial<globalThis.User.Target>} */
                // @ts-ignore
                const source = props.source;
                return { ...source };
            };

            /**
             * Reset the dirty form user to the initial state.
             */
            const resetDirtyUser = async () => {
                // Reset the dirty user.
                dirtyUser.value = initialState();

                // Get the selected accounts if an id is present.
                if (dirtyUser.value?.id) {
                    const target = dirtyUser.value;
                    const selectedAccounts = await users.fetchUserAccounts({
                        id: target.id,
                    });
                    target.accounts = selectedAccounts.isOk
                        ? selectedAccounts.value
                        : [];
                    dirtyUser.value = target;
                    resetSelection();
                }
            };

            /**
             * Reset the grid data selections.
             */
            const resetSelection = () => {
                const rows = accounts.value.map(_.createAccountRowForListBox);
                leftRowData.value = rows.filter(
                    (row) => !selectedIds.value.includes(row.id)
                );
                rightRowData.value = rows.filter((row) =>
                    selectedIds.value.includes(row.id)
                );
            };

            // ==== WATCHER ====

            // ==== EVENTS ====

            const setupGridColumns = () => {
                // Initialize left grid column definitions.
                leftColDefs.value = _.getLeftColumnDefs();
                // Initialize right grid column definitions.
                rightColDefs.value = _.getRightColumnDefs();
            };

            const onLeftGridApiUpdate = () => {
                /** @type {AgGrid.RowNode<Pick<globalThis.Account.Model, 'id' | 'name'>>[]} */
                const nodes = [];
                leftGridApi.value.forEachNode((node) => {
                    // console.log('[left]', node.data);
                    nodes.push(node);
                });
                leftRowData.value = nodes.map((n) => n.data);
            };

            const onRightGridApiUpdate = () => {
                /** @type {AgGrid.RowNode<Pick<globalThis.Account.Model, 'id' | 'name'>>[]} */
                const nodes = [];
                rightGridApi.value.forEachNode((node) => {
                    // console.log('[right]', node.data);
                    nodes.push(node);
                });
                rightRowData.value = nodes.map((n) => n.data);
            };

            /**
             * Update the selected accounts in the dirty user.
             */
            const onSelectionUpdate = () => {
                if (leftGridApi.value != null && rightGridApi.value != null) {
                    const selected = rightRowData.value.map((row) => row.id);
                    const target = dirtyUser.value;
                    target.accounts = accounts.value.filter((account) =>
                        selected.includes(account.id)
                    );
                    dirtyUser.value = target;
                }
            };

            /**
             * Update the row data once the grid api has been updated.
             */
            const onGridApiUpdate = () => {
                if (leftGridApi.value != null && rightGridApi.value != null) {
                    onLeftGridApiUpdate();
                    onRightGridApiUpdate();
                }

                // Update the tables.
                console.log(`[update::left]`, leftRowData.value);
                console.log(`[update::right]`, rightRowData.value);

                // Sync the selected account fields.
                onSelectionUpdate();
            };

            /**
             * Register target dropzone on the provided source API.
             * @param {'Left' | 'Right'} source
             * @param {AgGrid.GridApi} sourceApi
             */
            const addGridDropZone = (source, sourceApi) => {
                const targetApi =
                    source === 'Left' ? rightGridApi : leftGridApi;
                const targetDropZone = targetApi.value.getRowDropZoneParams({
                    onDragStop: (params) => {
                        /** @type {Pick<globalThis.Account.Model, 'id' | 'name'>} */
                        const droppedData = params.nodes[0].data;
                        switch (source) {
                            // When dragging from the left side, dropping on the right.
                            case 'Left':
                                leftGridApi.value.forEachNode((node) => {
                                    if (node.data.id === droppedData.id) {
                                        const row = node.data;
                                        leftGridApi.value.applyTransaction({
                                            remove: [row],
                                        });
                                    }
                                });
                                onGridApiUpdate();
                                return;
                            // When dragging from the right side, dropping on the left.
                            case 'Right':
                                rightGridApi.value.forEachNode((node) => {
                                    if (node.data.id === droppedData.id) {
                                        const row = node.data;
                                        rightGridApi.value.applyTransaction({
                                            remove: [row],
                                        });
                                    }
                                });
                                onGridApiUpdate();
                                return;
                            default:
                                console.error(
                                    `Unknown side ${source}. No operation performed.`
                                );
                                return;
                        }
                    },
                });
                if (!!targetDropZone?.getContainer) {
                    sourceApi.addRowDropZone(targetDropZone);
                }
            };

            /**
             * Store the grid API for the appropriate side.
             * @param {Events.GridReadyEvent} params
             * @param {'Left' | 'Right'} source
             */
            const onGridReady = (params, source) => {
                const api = params.api;

                if (source === 'Left') {
                    leftGridApi.value = api;
                }

                if (source === 'Right') {
                    rightGridApi.value = api;
                }

                if (leftGridApi.value && rightGridApi.value) {
                    addGridDropZone('Left', leftGridApi.value);
                    addGridDropZone('Right', rightGridApi.value);
                }

                api.sizeColumnsToFit();
            };

            /** Save the dirty user. */
            const onClickSave = (state) => {
                config.validationVisibility = 'live';
                if (state.valid) {
                    const target = { ...dirtyUser.value };
                    emit('submit', { target });
                } else {
                    console.error('Form is not valid.');
                }
            };

            /** Clear the dirty user. */
            const onClickCancel = () => {
                resetDirtyUser();
                emit('cancel', { reason: 'User canceled.' });
            };

            // ==== DEBUG ====
            /**
             * Computed debug frame.
             */
            const frame = computed(() => {
                // Prepare data.
                const data = [
                    DebugObject.create(`Is Editing?`, {
                        isEditing: props.isEditing,
                    }),
                    DebugObject.create(`Is Loading?`, {
                        isLoading: props.isLoading,
                    }),
                    DebugObject.create(`Source User Details`, {
                        source: props.source,
                    }),
                    DebugObject.create(`Dirty User`, {
                        dirtyUser: {
                            ...dirtyUser.value,
                        },
                    }),
                ];
                // Return new frame instance.
                return useDebugFrame({
                    isEnabled: true,
                    startHidden: true,
                    data,
                });
            });

            // ==== INITIALIZE ====

            onBeforeMount(async () => {
                setupGridColumns();
                resetDirtyUser();
                resetSelection();
            });

            onUnmounted(() => {
                leftGridApi.value = null;
                rightGridApi.value = null;
            });

            // ==== EXPOSE ====
            return {
                config,
                options,
                frame,
                accounts,
                dirtyUser,
                defaultColDef,
                areAccountsLoading,
                leftColDefs,
                rightColDefs,
                leftRowData,
                rightRowData,
                isAccountSelectionVisible,
                rowNodeIdGetter,
                onClickSave,
                onClickCancel,
                onGridReady,
            };
        },
    });
</script>

<style lang="scss">
    .user-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
