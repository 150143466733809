<template>
    <div class="flex main-section">
        <div class="flex-grow overflow-y-auto print:overflow-y-visible pb-6">
            <div class="border-b border-gray-200 outer-border">
                <div class="max-w-7xl mx-auto px-5 pt-6 sm:pt-0">
                    <SubNav :subTabs="currentTabs" />
                    <PageTitle></PageTitle>
                </div>
            </div>
            <slot name="mainContent">
                <!-- FALLBACK CONTENT -->
                Administrator Content
            </slot>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onBeforeMount, computed, toRefs } from 'vue';
    import { useRouter } from 'vue-router';

    // <!-- COMPONENTS -->
    import SubNav from '@/components/SubNav.vue';
    import PageTitle from '@/components/PageTitle.vue';

    // <!-- COMPOSABLES -->
    import { useTabDefs } from '@/hooks/useTabDefs';
    import { useAdminTabs } from '@/hooks/env/useSelectTabs';

    // <!-- CONSTANTS -->
    /** Tabs for the main container. */
    const tabDefinitions = {
        ipiadmin: [
            {
                name: 'Organizations',
                to: '/admin/organizations',
                current: false,
                position: 0,
            },
        ],
        commercial: [
            {
                name: 'Subscription Information',
                to: '/subscription-information',
                current: false,
                position: 1,
            },
        ],
        default: [
            {
                name: 'Accounts',
                to: '/admin/accounts',
                current: false,
                position: 2,
            },
            {
                name: 'Users',
                to: '/admin/users',
                current: false,
                position: 3,
            },
        ],
        nara: [
            {
                name: 'NARA Standards',
                to: '/admin/nara-standards',
                current: false,
                position: 4,
            },
        ],
    };

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AdminContainer',
        components: {
            SubNav,
            PageTitle,
        },
        setup(props) {
            // Get the router.
            const router = useRouter();

            // Create the tab functionality.
            //const { tabs: ipiadminTabs } = useIPIAdminTabs(tabDefinitions);
            const { tabs: definedTabs } = useAdminTabs(tabDefinitions);

            const { tabs: currentTabs, updateCurrentTabByRoute } = useTabDefs(
                { tabs: definedTabs },
                router
            );

            // Regsiter lifecycle.
            onBeforeMount(updateCurrentTabByRoute);

            // ==== EXPOSE ====
            return {
                currentTabs,
            };
        },
    });
</script>

<style scoped>
    .main-section {
        min-height: calc(100vh - 4rem);
    }

    .outer-border div {
        border: 0;
    }

    @media print {
        .main-section {
            height: auto;
        }
    }
</style>
