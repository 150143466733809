// <!-- API -->
import { computed } from 'vue';
import { computedEager, createEventHook, useAsyncState } from '@vueuse/core';
import { fetchPlans } from '@/api/v2/plans';

// <!-- UTILITIES -->
import { isAfter, isValid, add, intervalToDuration } from 'date-fns';
import { Service } from '@/utils/Service';
import { Option } from '@/utils/options';
import { Store, useStore } from 'vuex';
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- SUBMODULES -->

class Constants {
    /**
     * Instantiate a Constants instance.
     */
    constructor() {
        /** Is the project in debug mode? */
        this.IsDebug = process.env.NODE_ENV !== 'production';
        /** Represents the default plan index. */
        this.DefaultPlanIndex = /** @type {Plan.Model.Resource[]} */ ([]);
        /** Represents the default subscription length. */
        this.DefaultPlanLength = /** @type {Duration} */ ({ years: 1 });
        /** Represents the default enterprise limits. */
        this.DefaultEnterpriseLimits = /** @type {const} */ ({
            maxUsers: 50,
            maxLocations: 150,
        });
        /** Configure the default options. */
        this.DefaultOptions = /** @type {const} */ ([
            {
                id: 1,
                name: 'Free',
                pricePerYear: 0,
                maxUsers: 1,
                maxLocations: 3,
                /** @type {Duration} */
                duration: {
                    months: 3,
                },
            },
            {
                id: 2,
                name: 'Basic',
                pricePerYear: 100,
                maxUsers: 5,
                maxLocations: 10,
                /** @type {Duration} */
                duration: {
                    years: 1,
                },
            },
            {
                id: 3,
                name: 'Enterprise',
                pricePerYear: 200,
                maxUsers: 50,
                maxLocations: 150,
                /** @type {Duration} */
                duration: {
                    years: 1,
                },
            },
        ]);
    }
}

class Events {
    /**
     * Instantiate a Events instance.
     *
     * @param {Omit<PlanSelector, 'events' | 'state' | 'boot' | 'defineInterface' | 'defineConstants' | 'defineEvents' | 'defineState' | 'registerEventListeners'>} context
     */
    constructor(context) {
        // Add non-enumerable context property.
        this.context = context;
        Object.defineProperty(this, 'context', {
            enumerable: false,
            writable: false,
        });

        // Define the state.
        this.defineEventHooks();
    }

    defineEventHooks() {
        /** @type {EventHook<{ data: Plan.Model.Resource[] }>} */
        this.fetched = createEventHook();
        this.onPlansFetched = this.fetched.on;

        /** @type {EventHook<unknown>} */
        this.error = createEventHook();
        this.onError = this.error.on;
    }
}

class State {
    /**
     * Instantiate a State instance.
     *
     * @param {Omit<PlanSelector, 'state' | 'boot' | 'defineInterface' | 'defineConstants' | 'defineEvents' | 'defineState' | 'registerEventListeners'>} context
     */
    constructor(context) {
        // Add non-enumerable context property.
        this.context = context;
        Object.defineProperty(this, 'context', {
            enumerable: false,
            writable: false,
        });

        // Define the state.
        this.defineState();
        this.defineComputed();
    }

    /**
     * Define the reactive properties.
     */
    defineState() {
        const { events, constants } = this.context;
        const { DefaultPlanIndex } = constants;
        const { fetched, error } = events;

        // Define the async state for getting the plans.
        this.plans = useAsyncState(
            /** @param {Server.RequestConfig<Plan.Request.FetchIndex>} [config] */
            async (config) => {
                const result = await fetchPlans(config);
                return result.unwrapOr([...DefaultPlanIndex]);
            },
            [...DefaultPlanIndex],
            {
                immediate: true,
                throwError: true,
                resetOnExecute: false,
                onError: (e) => error.trigger(e),
                onSuccess: (data) => fetched.trigger({ data }),
            }
        );
    }

    defineComputed() {
        const { store } = this.context;

        // Define the current organization.
        this.currentOrganization = computed(() => {
            return store.state?.accounts?.organization;
        });

        // Define the current subscription.
        this.currentSubscription = computed(() => {
            const organization = this.currentOrganization.value;
            const subscription = organization?.currentSubscriptions?.[0];
            return subscription;
        });

        // Define the current plan.
        this.currentPlan = computed(() => {
            const subscription = this.currentSubscription.value;
            const plan = subscription?.plan;
            return plan;
        });

        // Define the computed property of options.
        this.options = computedEager(() => {
            const plans = this.plans.state.value;
            return plans.map((plan) => Option.create(plan.name, `${plan.id}`));
        });
    }
}

// <!-- PRIMARY MODULE -->

/**
 * The internal composable context.
 *
 * @typedef {{ store: Store<ECNBState> }} PlanSelectorOptions
 * @extends {Service<PlanSelectorOptions, Constants, Events, State, PlanSelector>}
 */
class PlanSelector extends Service {
    /**
     * Instantiates composable service class.
     *
     * @param {PlanSelectorOptions} props
     */
    constructor(props) {
        super(props);

        // Ensure the store is instantiated, if not present already.
        /** @type {Store<ECNBState>} */
        this.store = this.store ?? useStore();
    }

    /** Instantiate the submodule. */
    defineConstants() {
        return new Constants();
    }

    /** Instantiate the submodule. */
    defineEvents() {
        return new Events(this);
    }

    /** Instantiate the submodule. */
    defineState() {
        return new State(this);
    }

    /**
     * Fetch the plans from the server, asynchronously.
     *
     * @param {Server.RequestConfig<Plan.Request.FetchIndex>} [config]
     */
    fetchPlans(config) {
        return this.state.plans.execute();
    }

    /**
     * Get the duration associated with a given plan.
     *
     * @param {Pick<Plan.Model.Resource, 'id' | 'name' | 'isFreePlan' | 'isEnterprisePlan'>} plan
     * @returns {Duration}
     */
    getDuration(plan) {
        if (plan?.isFreePlan || plan?.name === 'Free') {
            return { months: 3 };
        } else {
            return this.constants.DefaultPlanLength;
        }
    }

    /**
     * Get the maximum number of users available to the specified plan. Enterprise plans will use current subscription information, if editing.
     *
     * @param {Pick<Plan.Model.Resource, 'id' | 'name' | 'isEnterprisePlan' | 'maxUsers'>} plan
     * @returns {number}
     */
    getUserLimit(plan) {
        return (
            plan?.maxUsers ??
            (plan?.isEnterprisePlan
                ? this.constants.DefaultEnterpriseLimits?.maxUsers
                : 1)
        );
    }

    /**
     * Get the maximum number of locations available to the specified plan. Enterprise plans will use current subscription information, if editing.
     *
     * @param {Pick<Plan.Model.Resource, 'id' | 'name' | 'isEnterprisePlan' | 'maxLocations'>} plan
     * @returns {number}
     */
    getLocationLimit(plan) {
        return (
            plan?.maxLocations ??
            (plan?.isEnterprisePlan
                ? this.constants.DefaultEnterpriseLimits?.maxLocations
                : 3)
        );
    }

    /**
     * Get plan details based on id.
     *
     * @param {number} id
     */
    getPlanInformation(id) {
        const plans = this.state.plans.state.value;
        const plan = plans.find((plan) => plan.id === id) ?? plans[0];
        const duration = this.getDuration(plan);
        const maxUsers = this.getUserLimit(plan);
        const maxLocations = this.getLocationLimit(plan);
        const info = Object.assign({}, plan, {
            duration,
            maxUsers,
            maxLocations,
        });
        console.dir(info);
        return info;
    }

    /**
     * Calculate the remaining subscription length, from a known expiration date.
     * @param {Date} expirationDate
     * @returns {Duration}
     */
    calculateRemainingSubscriptionLengthFromExpirationDate(expirationDate) {
        if (!isValid(expirationDate) || isAfter(Date.now(), expirationDate)) {
            // Empty duration since invalid date or already expired.
            return {};
        }

        // Calculate the interval duration.
        return intervalToDuration({
            start: Date.now(),
            end: expirationDate,
        });
    }

    /**
     * Given a subscription length, calculate the expiration date based off of today's date.
     * @param {Duration} duration
     */
    calculateExpirationDateFromSubscriptionLength(duration) {
        // Get the current date.
        const currentDate = new Date(Date.now());
        // Calculate the expiration date.
        const rawDate = add(currentDate, duration);
        // Offset the selected amount by a single day.
        const expirationDate = add(rawDate, { days: 1 });
        // Return the expiration date if it's valid.
        return isValid(expirationDate) ? expirationDate : currentDate;
    }

    /** Expose this instance, by default. */
    expose() {
        this.events.onPlansFetched(({ data }) => {
            console.log('Fetched plans.');
            console.dir(data);
        });

        return this;
    }
}

// <!-- NAMED EXPORTS -->
/**
 * Instantiates internal composable class, then returns its exposed API.
 */
export const usePlanSelector = () => {
    const instance = new PlanSelector({ store: useStore() });
    // instance.state.variable
    return instance.expose();
};

// <!-- DEFAULT EXPORT -->
export default usePlanSelector;
