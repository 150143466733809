// <!-- API -->
import { ref } from 'vue';
import { computedEager, createEventHook, promiseTimeout } from '@vueuse/core';
import organizations from '@/api/v2/organizations';
import subscriptions from '@/api/v2/subscriptions';

// <!-- ENUMS -->
import {
    ReminderFrequency,
    TemperatureScale,
    CountryName,
    StateName,
} from '@/enums';

// <!-- MODELS -->
import { Plan } from '@/models/v2/plans';
import { Subscription } from '@/models/v2/subscriptions';
import { Organization } from '@/models/v2/organizations';

// <!-- UTILITIES -->
import clone from 'just-clone';
import pick from 'just-pick';
import { Result } from 'true-myth/dist/result';
import { Maybe } from 'true-myth/dist/maybe';
import { add } from 'date-fns';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- SUBMODULES -->
/**
 * Represents the constant, static state.
 *
 * @class
 * Submodule for the {@link SubscriptionManager} composable.
 */
class Constants {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineConstants();
    }

    /**
     * Define the constants to be used.
     */
    defineConstants() {
        /**
         * Is debug mode?
         * @type {Readonly<boolean>}
         */
        this.IsDebug = process.env.NODE_ENV !== 'production';

        /** Status IDs. */
        this.StatusIDs = /** @type {const} */ ({
            success: 'success',
            failure: 'failure',
        });

        /** Alert IDs. */
        this.AlertIDs = /** @type {const} */ ({
            'edit-error': 'edit-error',
            'edit-success': 'edit-success',
            'edit-warning': 'edit-warning',
            'refresh-error': 'refresh-error',
            'refresh-success': 'refresh-success',
            'refresh-warning': 'refresh-warning',
        });

        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ({
            extendSubscription: 'extendSubscription',
            upgradeSubscription: 'upgradeSubscription',
        });

        /** @type {globalThis.Organization.SubscriptionFormData} */
        this.DefaultFormData = {
            form: 'edit',
            // ORGANIZATION
            id: null,
            name: '',
            numberOfUsers: 0,
            numberOfLocations: 0,
            // ORGANIZATION::SUBSCRIPTION
            planId: null,
            planName: 'None',
            subscriptionId: null,
            activeAt: null,
            expireAt: null,
            maxUsers: Infinity,
            maxLocations: Infinity,
            // ORGANIZATION::REGION
            address: {
                country: CountryName.ByISO3['USA'],
                state: StateName.ByISO2['US-NY'],
                city: '',
            },
            // ORGANIZATION::EMAIL
            email: {
                contact: '',
                billing: '',
                reminderFrequency: ReminderFrequency.Never,
            },
            // ORGANIZATION::DETAILS
            timezone: 'UTC',
            temperatureScale: TemperatureScale.Fahrenheit,
            treeLabels: ['Site', 'Building', 'Floor', 'Room'],
        };
    }
}

/**
 * Defines the manager lifecycle events.
 *
 * @class
 * Submodule for the {@link SubscriptionManager} composable.
 */
class Events {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineEvents();
    }

    /**
     * Define the event hooks.
     */
    defineEvents() {
        // ==== STATUS ====
        {
            /**
             * Contains loading event hooks.
             */
            this.load = {
                /**
                 * Hook triggered when a loading operation begins. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                start: createEventHook(),
                /**
                 * Hook triggered when a loading operation ends. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                stop: createEventHook(),
            };
        }

        // ==== ALERTS ====
        {
            /**
             * Contains notification alert event hooks.
             */
            this.alert = {
                /**
                 * Hook triggered in order to clear the specified alerts.
                 * @type {Vue.EventHook<(keyof Constants['AlertIDs'])[]>}
                 */
                clear: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                success: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                warning: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                error: createEventHook(),
            };
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Contains modal dialog event hooks.
             */
            this.modal = {
                /**
                 * Hook triggered when any modal is opened.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                open: createEventHook(),
                /**
                 * Hook triggered when any modal is closed.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                close: createEventHook(),
            };
        }

        // ==== FORM ACTIONS ====
        {
            /**
             * Contains specific form action event hooks.
             */
            this.form = {
                /**
                 * Contains edit resource modal dialog event hooks.
                 */
                edit: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: globalThis.Organization.SubscriptionFormData }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains confirm delete resource modal dialog event hooks.
                 */
                extend: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: globalThis.Organization.ChangeSubscriptionFormData }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains confirm delete resource modal dialog event hooks.
                 */
                upgrade: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: globalThis.Organization.ChangeSubscriptionFormData }>}
                     */
                    submit: createEventHook(),
                },
            };
        }

        // ==== MANAGER ACTIONS ====
        {
            /**
             * Hook triggered when the page must redirect to a different location.
             * @type {Vue.EventHook<{ to: Router.RouteLocationRaw }>}
             */
            this.redirect = createEventHook();
            /**
             * Hook triggered when the page refreshes the index.
             * @type {Vue.EventHook<{ resource: globalThis.Organization.Model }>}
             */
            this.refreshed = createEventHook();
        }
    }
}

/**
 * Represents the reactive, computed, and async class state.
 *
 * @class
 * Submodule for the {@link SubscriptionManager} composable.
 */
class State {
    /**
     * Create the submodule.
     * @param {Pick<SubscriptionManager, 'store' | 'constants'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineReactive();
        this.defineComputed();
        this.defineAsync();
    }

    /**
     * Assign the context.
     * @param {Pick<SubscriptionManager, 'store' | 'constants'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the reactive properties.
     */
    defineReactive() {
        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             * @type {Vue.Ref<boolean>}
             */
            this.loading = ref(false);
            /**
             * Indicates if the view is currently editing content.
             * @type {Vue.Ref<boolean>}
             */
            this.editing = ref(false);
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies the currently open modal by id. Set to `null` when no modal is open.
             * @type {Vue.Ref<keyof Constants['ModalIDs']>}
             */
            this.modal = ref(null);
        }

        // ==== FORM DATA ====
        {
            const { DefaultFormData } = this.context.constants;

            /**
             * Specifies the original form data.
             * @type {Vue.Ref<globalThis.Organization.SubscriptionFormData>}
             */
            this.initialData = ref({ ...DefaultFormData });
        }
    }

    /**
     * Define the computed properties.
     */
    defineComputed() {
        // ==== STORE ====
        {
            // Get the store so we can access shared state.
            const { store } = this.context;

            /** The current authenticated user, if one is present. */
            this.currentUser = computedEager(() => store.state?.users?.me);

            /** The current selected organization, if one is selected. */
            this.currentOrganization = computedEager(
                () => store.state?.accounts?.organization
            );
        }

        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             */
            this.isLoading = computedEager(() => this.loading.value === true);
            /**
             * Indicates if the view is not currently loading content.
             */
            this.isIdling = computedEager(() => this.loading.value !== true);
            /**
             * Indicates if the view is currently editing content.
             */
            this.isEditing = computedEager(() => this.editing.value === true);
            /**
             * Indicates if the view is currently editing content.
             */
            this.isViewing = computedEager(() => this.editing.value !== true);
        }

        // ==== FORM DATA ====
        {
            /**
             * Specifies the initial change subscription modal form data.
             */
            this.currentSubscriptionData = computedEager(() => {
                const initialData = clone(this.initialData.value);
                const formData = pick(initialData, [
                    'id',
                    'name',
                    'numberOfUsers',
                    'numberOfLocations',
                    'planId',
                    'planName',
                    'subscriptionId',
                    'activeAt',
                    'expireAt',
                    'maxUsers',
                    'maxLocations',
                ]);
                return formData;
            });
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isUpgradeModalOpen = computedEager(
                () => this.modal.value === 'upgradeSubscription'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isExtendModalOpen = computedEager(
                () => this.modal.value === 'extendSubscription'
            );
        }
    }

    /**
     * Define the async properties.
     */
    defineAsync() {}
}

/**
 * @class
 * Submodule for the {@link SubscriptionManager} composable.
 */
class Methods {
    /**
     * Create the submodule.
     * @param {Pick<SubscriptionManager, 'store' | 'router' | 'constants' | 'events' | 'state'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineHooks();
        this.defineTriggers();
        this.defineActions();
        this.defineUtilities();
    }

    /**
     * Assign the context.
     * @param {Pick<SubscriptionManager, 'store' | 'router' | 'constants' | 'events' | 'state'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the event triggers.
     */
    defineHooks() {
        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Register a callback invoked at the start of an async content loading operation. */
            this.onLoadStarted = load.start.on;
            /** Register a callback invoked at the end of an async content loading operation. */
            this.onLoadStopped = load.stop.on;
        }

        // ==== ALERTS ====
        {
            const { alert } = this.context.events;

            /** Register a callback invoked after alert notifications are explicitly cleared. */
            this.onClearAlerts = alert.clear.on;
            /** Register a callback invoked after a successful alert notification is sent. */
            this.onAlertSuccess = alert.success.on;
            /** Register a callback invoked after a warning alert notification is sent. */
            this.onAlertWarning = alert.warning.on;
            /** Register a callback invoked after an error alert notification is sent. */
            this.onAlertError = alert.error.on;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Register a callback invoked when a modal is opened. */
            this.onModalOpened = modal.open.on;
            /** Register a callback invoked when a modal is closed. */
            this.onModalClosed = modal.close.on;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onEditButtonClicked = form.edit.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onUpgradeButtonClicked = form.upgrade.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onExtendButtonClicked = form.extend.click.on;

            /** Register a callback invoked when the user cancels the specified form. */
            this.onEditCanceled = form.edit.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onUpgradeCanceled = form.upgrade.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onExtendCanceled = form.extend.cancel.on;

            /** Register a callback invoked when the user submits the specified form. */
            this.onEditSubmitted = form.edit.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onUpgradeSubmitted = form.upgrade.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onExtendSubmitted = form.extend.submit.on;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { events } = this.context;

            /** Register a callback invoked when the page must redirect to another location. */
            this.onRedirect = events.redirect.on;
            /** Register a callback invoked when the organizations have been refreshed. */
            this.onRefreshed = events.refreshed.on;
        }
    }

    /**
     * Define the event triggers.
     */
    defineTriggers() {
        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Called at the start of an async content loading operation. */
            this.startLoading = load.start.trigger;
            /** Called at the completion of an async content loading operation. */
            this.stopLoading = load.stop.trigger;
        }

        // ==== ALERTS ====
        {
            const { AlertIDs } = this.context.constants;
            const { alert } = this.context.events;

            /** Collection of all alert ids. Useful as a default. */
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /** Clear the specified alerts. If nothing is passed, all domain alerts are cleared. */
            this.clearAlerts = (targets = ids) => alert.clear.trigger(targets);
            /** Sends a successful alert notification. */
            this.sendSuccessAlert = alert.success.trigger;
            /** Sends a warning alert notification. */
            this.sendWarningAlert = alert.warning.trigger;
            /** Sends an error alert notification. */
            this.sendErrorAlert = alert.error.trigger;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Open the modal specified by id. */
            this.openModal = modal.open.trigger;
            /** Close the modal specified by id. */
            this.closeModal = modal.close.trigger;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Handle a user click on the specified interface element. */
            this.clickEditButton = form.edit.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickUpgradeButton = form.upgrade.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickExtendButton = form.extend.click.trigger;

            /** Triggered when the user cancels out of the specified form. */
            this.cancelEdit = form.edit.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelUpgrade = form.upgrade.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelExtend = form.extend.cancel.trigger;

            /** Triggered when the user submits the specified form. */
            this.submitEdit = form.edit.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitUpgrade = form.upgrade.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitExtend = form.extend.submit.trigger;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { events } = this.context;

            /** Send a redirect event with a `RouteLocationRaw` payload. */
            this.redirect = events.redirect.trigger;
            /** Send an updated payload once the organizations are refreshed. */
            this.refreshed = events.refreshed.trigger;
        }
    }

    /**
     * Define action methods.
     */
    defineActions() {
        // ==== FORM ACTIONS ====
        {
            /**
             * Get the sanitized request.
             *
             * @param {globalThis.Organization.SubscriptionFormData} target
             * @returns {globalThis.Organization.Request.UpdateResource}
             */
            this.getSanitizedUpdateRequest = (target) => {
                // Get the properties from the target.
                const body = { ...target };

                // Sanitize the reminder field.
                if (!ReminderFrequency.isValue(body.email?.reminderFrequency)) {
                    body.email.reminderFrequency = ReminderFrequency.Never;
                }

                // Sanitize the tree labels.
                if (body?.treeLabels?.length === 4) {
                    // Copy the value.
                    body.treeLabels = [...body.treeLabels];
                } else {
                    // Use server default.
                    body.treeLabels = null;
                }

                // Create the payload.
                return {
                    id: body.id,
                    name: body.name,
                    billing_email: body?.email?.billing,
                    contact_email: body?.email?.contact,
                    reminder_frequency: body?.email?.reminderFrequency,
                    city: body?.address?.city,
                    state: body?.address?.state,
                    country: body?.address?.country,
                    tree_labels: body?.treeLabels,
                    timezone: body?.timezone,
                    temperature_scale: body?.temperatureScale,
                };
            };

            /**
             * Get the sanitized request.
             *
             * @param {globalThis.Organization.SubscriptionFormData} target
             * returns {globalThis.Organization.Request.UpdateResource}
             */
            this.getSanitizedExtendRequest = (target) => {};

            /**
             * Update an existing organization resource on the server.
             * @param {globalThis.Organization.SubscriptionFormData} target
             */
            this.updateResource = async (target) => {
                console.log(`[update::resource] <${target.id}>`);
                // Get the sanitized request.
                const request = Object.freeze(
                    this.getSanitizedUpdateRequest(target)
                );
                // Send the response.
                const response = await organizations.updateOrganizationById(
                    request
                );
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (organization) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'edit-success',
                            content: 'Updated 1 organization successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshResource();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'edit-error',
                            // @ts-ignore
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Upgrade or extend the current subscription by passing data to the cart.
             * @param {globalThis.Organization.ChangeSubscriptionFormData} target
             */
            this.changeSubscription = async (target) => {
                console.log(`[upgrade::subscription] <${target.id}>`);
                // Get the sanitized request.
                // const request = Object.freeze(
                //     this.getSanitizedUpdateRequest(target)
                // );
                // Send the response.
                // const response = await organizations.updateOrganizationById(
                //     request
                // );
                console.dir('sending request example.');
                const response = Result.err(
                    new Error('Endpoint is not implemented.')
                );
                // Close the modals.
                this.closeModal({ id: 'extendSubscription' });
                this.closeModal({ id: 'upgradeSubscription' });
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (organization) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'edit-success',
                            content: 'Request sent to shopping cart.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshResource();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'edit-error',
                            content:
                                // @ts-ignore
                                e?.message ?? e?.error?.response?.data?.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };
        }

        // ==== MANAGER ACTIONS ====
        {
            // Get the required submodules.
            const { state, constants } = this.context;

            /**
             * Refresh the resource.
             */
            this.refreshResource = async () => {
                // Clear related alerts.
                this.queueClearAlerts([
                    'refresh-success',
                    'refresh-warning',
                    'refresh-error',
                ]);
                try {
                    // Update the resource index for the table data.
                    this.startLoading('[refresh::organization]');
                    // Stop editing, if we already are.
                    state.editing.value = false;
                    // Get the request for the current organization.
                    const request = { id: state.currentOrganization.value?.id };
                    // Fetch collection of organizations.
                    const response = await organizations?.fetchOrganizationById(
                        request
                    );
                    // Handle the response.
                    if (response.isErr) throw response.error;
                    // Create row data models from resource index.
                    const resource = response.isOk ? response.value : null;
                    // Trigger the index refresh.
                    this.refreshed({ resource });
                    // Debug specific alerts.
                    if (constants.IsDebug) {
                        // Send a success alert.
                        this.sendSuccessAlert({
                            id: 'refresh-success',
                            content: `Fetched 1 organization successfully.`,
                            dismissable: false,
                            ttl: 5000,
                        });
                    }
                } catch (e) {
                    // Send error event.
                    this.handleClientError('refresh-error', e);
                } finally {
                    // Update the loading status.
                    this.stopLoading('[refresh::organization]');
                }
            };
        }
    }

    /**
     * Define utility functions.
     */
    defineUtilities() {
        // ==== ERROR HANDLING ====
        {
            const { AlertIDs } = this.context.constants;
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /**
             * Handle the passed client error.
             * @param {keyof Constants['AlertIDs']} id The alert tag.
             * @param {Client.Error<Client.ErrorType>} err
             */
            this.handleClientError = (id, err) => {
                console.warn(`[${id}] ${err?.type}.`);
                this.sendErrorAlert({
                    id,
                    title: err?.title ?? 'Error',
                    messages: err?.messages ?? [
                        'An unexpected error occurred.',
                    ],
                    dismissable: true,
                    ttl: 10000,
                });
            };

            /**
             * Expire alerts after a scheduled delay.
             *
             * @param {(keyof Constants['AlertIDs'])[]} [targets]
             * @param {number} [delayInMilliseconds]
             */
            this.queueClearAlerts = async (
                targets = ids,
                delayInMilliseconds = 5000
            ) => {
                await promiseTimeout(delayInMilliseconds);
                this.clearAlerts(targets);
            };
        }

        // ==== UTILITY FUNCTIONS ====
        {
            /**
             * Compare two values.
             * @type {AgGrid.ColumnDef['comparator']}
             */
            this.compareByLowercase = (valueA, valueB, nodeA, nodeB) => {
                /** @type {string} */
                const valueALower = valueA.toLowerCase().trim();
                /** @type {string} */
                const valueBLower = valueB.toLowerCase().trim();
                // Return comparison value.
                return valueALower.localeCompare(valueBLower, 'en');
            };

            /**
             * Format the date value for the handled field.
             * @type {AgGrid.ValueFormatterFunc<string>}
             */
            this.formatDate = (params) => {
                // Get the value.
                const date = Maybe.of(params.value).map((dt) => new Date(dt));
                // Conditionally execute based on if row was found.
                const formatted = date.match({
                    Just: (dt) => {
                        return dt.toLocaleDateString('en-ca');
                    },
                    Nothing: () => 'No date provided.',
                });
                // Return the value.
                return formatted;
            };
        }
    }
}

// <!-- MANAGER MODULE -->

/**
 * Orchestrates the organization management features.
 */
class SubscriptionManager {
    /**
     * Instantiate a new manager.
     * @param {Parameters<SubscriptionManager['boot']>[0]} [props] Composable parameters.
     */
    constructor(props = {}) {
        // Bind or inject services required by any submodules.
        this.boot(props);
        // Define the submodule interface.
        this.defineInterface();
        // Register the event listeners.
        this.registerEventListeners();
    }

    /**
     * Assign the context.
     * @param {Object} services Composable parameters.
     * @param {Vuex.Store<ECNBState>} [services.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {Router.Instance} [services.router] Optional router to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [services.alerts] Alerts composable.
     */
    boot({ store, router, alerts }) {
        /** @type {Vuex.Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {Router.Instance} */
        this.router = router ?? useRouter();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();
    }

    /**
     * Initializes the submodules needed to fulfill the manager's tasks.
     */
    defineInterface() {
        // Define submodules.
        this.constants = new Constants();
        this.events = new Events();
        this.state = new State(this);
        this.methods = new Methods(this);
    }

    /**
     * Register the internal event listener callbacks.
     */
    registerEventListeners() {
        // Provide access to state and methods.
        const { state, methods: _ } = this;

        // ==== STATUS ====
        {
            _.onLoadStarted((tag) => {
                console.time(tag);
                state.loading.value = true;
            });

            _.onLoadStopped((tag) => {
                state.loading.value = false;
                console.timeEnd(tag);
            });
        }

        // ==== ALERTS ====
        {
            const { clearAlert, createAlert, pushAlert } = this.alerts.methods;

            // Clear the specified alerts.
            _.onClearAlerts((ids) => {
                ids.forEach((id) => clearAlert(id));
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertSuccess((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'success',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertWarning((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'warning',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertError((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'error',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });
        }

        // ==== MODAL DIALOG ====
        {
            // Sets the active modal.
            _.onModalOpened(({ id }) => {
                state.modal.value = id;
            });

            // Closes the active modal if it matches the given id.
            _.onModalClosed(({ id }) => {
                if (state.modal.value === id) {
                    state.modal.value = null;
                }
            });
        }

        // ==== FORM ACTIONS ====
        {
            // Sets the appropriate target and opens the appropriate dialog.
            _.onEditButtonClicked(async ({ event, id }) => {
                console.log(`[click::edit] <${id}>`, event);
                // Clear relevant alerts.
                _.queueClearAlerts([
                    'edit-success',
                    'edit-warning',
                    'edit-error',
                    'refresh-error',
                ]);
                // Close any open modals.
                _.closeModal({ id: 'extendSubscription' });
                _.closeModal({ id: 'upgradeSubscription' });
                // Set editing to true.
                state.editing.value = true;
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onUpgradeButtonClicked(({ event }) => {
                console.log(`[click::upgrade]`, event);
                // Clear relevant alerts.
                _.queueClearAlerts([
                    'edit-success',
                    'edit-warning',
                    'edit-error',
                    'refresh-error',
                ]);
                // Close any open modals.
                _.closeModal({ id: 'extendSubscription' });
                _.closeModal({ id: 'upgradeSubscription' });
                // Open the appropriate modal.
                _.openModal({ id: 'upgradeSubscription' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onExtendButtonClicked(({ event }) => {
                console.log(`[click::extend]`, event);
                // Clear relevant alerts.
                _.queueClearAlerts([
                    'edit-success',
                    'edit-warning',
                    'edit-error',
                    'refresh-error',
                ]);
                // Close any open modals.
                _.closeModal({ id: 'extendSubscription' });
                _.closeModal({ id: 'upgradeSubscription' });
                // Open the appropriate modal.
                _.openModal({ id: 'extendSubscription' });
            });

            // Closes the appropriate dialog and clears the target.
            _.onEditCanceled((event) => {
                console.log(`[cancel::edit]`, event?.reason);
                state.editing.value = false;
            });

            // Closes the appropriate dialog and clears the target.
            _.onUpgradeCanceled((event) => {
                console.log(`[cancel::upgrade]`, event?.reason);
                _.closeModal({ id: 'upgradeSubscription' });
            });

            // Closes the appropriate dialog and clears the target.
            _.onExtendCanceled((event) => {
                console.log(`[cancel::extend]`, event?.reason);
                _.closeModal({ id: 'extendSubscription' });
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onEditSubmitted(async (event) => {
                console.log(
                    `[submit:edit] <${event?.target?.name}> [${event?.target?.id}]`
                );
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:edit');
                    await _.updateResource(event?.target);
                    _.redirect({ to: '/subscription-information' });
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:edit');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onUpgradeSubmitted(async (event) => {
                console.log(
                    `[submit:upgrade] <${event?.target?.name}> [${event?.target?.id}]`
                );
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:upgrade');
                    await _.changeSubscription(event?.target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:upgrade');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onExtendSubmitted(async (event) => {
                console.log(
                    `[submit:extend] <${event?.target?.name}> [${event?.target?.id}]`
                );
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:extend');
                    await _.changeSubscription(event?.target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:extend');
                }
            });
        }

        // ==== MANAGER ACTIONS ====
        {
            // Redirect the page using the specified location.
            _.onRedirect(({ to }) => {
                console.log(`[redirect]`, to);
                this.router.push(to);
            });

            // Refresh the table data.
            _.onRefreshed(({ resource }) => {
                // Populate the form data using the specified resource.
                const formData = state.initialData.value;

                console.dir(resource);
                if (!!resource) {
                    // Set primary details.
                    formData.form = 'edit';
                    formData.id = resource.id;
                    formData.name = resource.name;
                    formData.numberOfUsers = resource.numberOfUsers;
                    formData.numberOfLocations = resource.numberOfLocations;
                    formData.timezone = resource.timezone;
                    formData.temperatureScale = resource.temperatureScale;
                    formData.treeLabels =
                        resource?.treeLabels?.length <= 4
                            ? [...resource.treeLabels]
                            : ['', '', '', ''];

                    // Set subscription details.
                    const currentSubscription =
                        resource.currentSubscriptions?.[0];
                    formData.planId = currentSubscription?.plan?.id;
                    formData.planName = currentSubscription?.plan?.name;
                    formData.subscriptionId = currentSubscription?.id;
                    formData.activeAt = currentSubscription?.activeAt;
                    formData.expireAt = currentSubscription?.expireAt;
                    formData.maxUsers =
                        currentSubscription?.maxUsers ?? Infinity;
                    formData.maxLocations =
                        currentSubscription?.maxLocations ?? Infinity;

                    // Set region details.
                    const address = resource.address;
                    formData.address = {
                        country: address?.country,
                        state: address?.state,
                        city: address?.city,
                    };

                    // Set email details.
                    const email = resource.email;
                    formData.email = {
                        contact: email?.contact,
                        billing: email?.billing,
                        reminderFrequency: resource.reminderFrequency,
                    };
                }

                // Update the source form data.
                state.initialData.value = formData;
            });
        }
    }
}

// <!-- COMPOSABLE -->

/**
 * Composable feature for managing the organization admin view-model state.
 */
export const useSubscriptionManager = () => {
    const manager = new SubscriptionManager();
    return manager;
};

// <!-- DEFAULT -->
export default useSubscriptionManager;
