// <!-- API -->
import { ref } from 'vue';
import { computedEager, createEventHook, promiseTimeout } from '@vueuse/core';
import accounts from '@/api/v2/accounts';
import organizations from '@/api/v2/organizations';

// <!-- ENUMS -->
import { ReminderFrequency } from '@/enums';

// <!-- MODELS -->
import { Account } from '@/models/v2/accounts';

// <!-- COMPONENTS -->
import AccountManagerTableIcons from '~AccountManager/components/AccountManagerTableIcons.vue';

// <!-- UTILITIES -->
import is from '@sindresorhus/is';
import clone from 'just-clone';
import omit from 'just-omit';
import isNil from 'lodash-es/isNil';
import { DateTimeISO } from '@/utils/datetime';
import { formatISO, isValid } from 'date-fns';
import { Maybe } from 'true-myth/dist/maybe';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useAlerts } from '@/components/alerts/hooks/useAlerts';
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { ECNBState } from '@/store/types/ECNBStore';

// <!-- SUBMODULES -->

/**
 * Defines the manager lifecycle events.
 *
 * @class
 * Submodule for the {@link AccountManager} composable.
 */
class Events {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineEvents();
    }

    /**
     * Define the event hooks.
     */
    defineEvents() {
        // ==== STATUS ====
        {
            /**
             * Contains loading event hooks.
             */
            this.load = {
                /**
                 * Hook triggered when a loading operation begins. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                start: createEventHook(),
                /**
                 * Hook triggered when a loading operation ends. Contains a useful debugging string tag for the operation.
                 * @type {Vue.EventHook<string>}
                 */
                stop: createEventHook(),
            };
        }

        // ==== ALERTS ====
        {
            /**
             * Contains notification alert event hooks.
             */
            this.alert = {
                /**
                 * Hook triggered in order to clear the specified alerts.
                 * @type {Vue.EventHook<(keyof Constants['AlertIDs'])[]>}
                 */
                clear: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                success: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                warning: createEventHook(),
                /**
                 * Hook triggered when a successful alert notification is fired.
                 * @type {Vue.EventHook<{ id: keyof Constants['AlertIDs'], title?: string, content?: string, messages?: string[], dismissable?: boolean, ttl?: number }>}
                 */
                error: createEventHook(),
            };
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Contains modal dialog event hooks.
             */
            this.modal = {
                /**
                 * Hook triggered when any modal is opened.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                open: createEventHook(),
                /**
                 * Hook triggered when any modal is closed.
                 * @type {Vue.EventHook<{ id: keyof Constants['ModalIDs'] }>}
                 */
                close: createEventHook(),
            };
        }

        // ==== FORM ACTIONS ====
        {
            /**
             * Contains specific form action event hooks.
             */
            this.form = {
                /**
                 * Contains add resource modal dialog event hooks.
                 */
                add: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: Omit<globalThis.Account.Target, 'id'> }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains edit resource modal dialog event hooks.
                 */
                edit: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<{ target: globalThis.Account.Target }>}
                     */
                    submit: createEventHook(),
                },
                /**
                 * Contains confirm delete resource modal dialog event hooks.
                 */
                delete: {
                    /**
                     * Hook triggered when the resource button is clicked.
                     * @type {Vue.EventHook<{ event: MouseEvent, id: number }>}
                     */
                    click: createEventHook(),
                    /**
                     * Hook triggered when the resource form is canceled.
                     * @type {Vue.EventHook<{ reason?: string }>}
                     */
                    cancel: createEventHook(),
                    /**
                     * Hook triggered when the resource form is submitted.
                     * @type {Vue.EventHook<void>}
                     */
                    submit: createEventHook(),
                },
            };
        }

        // ==== MANAGER ACTIONS ====
        {
            /**
             * Hook triggered when the page must redirect to a different location.
             * @type {Vue.EventHook<{ to: Router.RouteLocationRaw }>}
             */
            this.redirect = createEventHook();
            /**
             * Hook triggered when the page refreshes the index.
             * @type {Vue.EventHook<{ index: globalThis.Account.Model[] }>}
             */
            this.refreshed = createEventHook();
        }
    }
}

/**
 * Represents the constant, static state.
 *
 * @class
 * Submodule for the {@link AccountManager} composable.
 */
class Constants {
    /**
     * Create the submodule.
     */
    constructor() {
        this.defineConstants();
    }

    /**
     * Define the constants to be used.
     */
    defineConstants() {
        /**
         * Is debug mode?
         * @type {Readonly<boolean>}
         */
        this.IsDebug = process.env.NODE_ENV !== 'production';

        /** Status IDs. */
        this.StatusIDs = /** @type {const} */ ({
            success: 'success',
            failure: 'failure',
        });

        /** Alert IDs. */
        this.AlertIDs = /** @type {const} */ ({
            'add-error': 'add-error',
            'add-success': 'add-success',
            'add-warning': 'add-warning',
            'delete-error': 'delete-error',
            'delete-success': 'delete-success',
            'delete-warning': 'delete-warning',
            'edit-error': 'edit-error',
            'edit-success': 'edit-success',
            'edit-warning': 'edit-warning',
            'refresh-error': 'refresh-error',
            'refresh-success': 'refresh-success',
            'refresh-warning': 'refresh-warning',
        });

        /** Modal IDs. */
        this.ModalIDs = /** @type {const} */ ({
            addAccount: 'addAccount',
            editAccount: 'editAccount',
            viewAccount: 'viewAccount',
            deleteAccount: 'deleteAccount',
        });

        /** @type {AgGrid.ColumnDef} */
        this.DefaultColumnDefinition = {
            resizable: true,
            sortable: true,
            filter: true,
            floatingFilter: true,
            floatingFilterComponentParams: { suppressFilterButton: true },
            suppressMovable: true,
            suppressMenu: true,
            lockPosition: true,
            minWidth: 150,
            flex: 1,
            cellClass: 'leading-5 py-4 break-normal',
            headerClass: 'whitespace-normal text-center',
            wrapHeaderText: true,
        };

        /** @type {globalThis.Account.Row} */
        this.DefaultAccountRow = {
            id: 1,
            name: 'Test Account 1',
            lastUploadAt: new Date('12/17/2023'),
        };

        /** @type {globalThis.Account.Target} */
        this.DefaultAccountTarget = {
            id: null,
            organizationId: null,
            name: '',
            reminderFrequency: 'never',
            temperatureScale: 'F',
            timezone: 'America/New_York',
            treeLabels: ['Site', 'Building', 'Floor', 'Room'],
        };
    }
}

/**
 * Represents the reactive, computed, and async class state.
 *
 * @class
 * Submodule for the {@link AccountManager} composable.
 */
class State {
    /**
     * Create the submodule.
     * @param {Pick<AccountManager, 'store' | 'constants'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineReactive();
        this.defineComputed();
        this.defineAsync();
    }

    /**
     * Assign the context.
     * @param {Pick<AccountManager, 'store' | 'constants'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the reactive properties.
     */
    defineReactive() {
        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             * @type {Vue.Ref<boolean>}
             */
            this.loading = ref(false);
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies the currently open modal by id. Set to `null` when no modal is open.
             * @type {Vue.Ref<keyof Constants['ModalIDs']>}
             */
            this.modal = ref(null);
        }

        // ==== RESOURCE TARGETS ====
        {
            /**
             * Contains targets containing resource information for different actions.
             */
            this.targets = {
                /**
                 * Form data for the create resource form.
                 * @type {Vue.Ref<Omit<globalThis.Account.Target, 'id'>>}
                 */
                add: ref(null),
                /**
                 * Form data for the update resource form.
                 * @type {Vue.Ref<Omit<globalThis.Account.Target, 'organizationId'>>}
                 */
                edit: ref(null),
                /**
                 * Form data for the delete resource form.
                 * @type {Vue.Ref<Pick<globalThis.Account.Target, 'id'>>}
                 */
                delete: ref(null),
            };
        }

        // ==== GRID ====
        {
            const { constants } = this.context;

            /**
             * Collection of row data models used to populate the AgGrid table.
             * @type {Vue.Ref<globalThis.Account.Row[]>}
             */
            this.rowData = ref([constants.DefaultAccountRow]);
            /**
             * Collection of column definitions used to configure the AgGrid table.
             * @type {Vue.Ref<AgGrid.ColumnDef[]>}
             */
            this.colDefs = ref([]);
        }
    }

    /**
     * Define the computed properties.
     */
    defineComputed() {
        // ==== STORE ====
        {
            // Get the store so we can access shared state.
            const { store } = this.context;

            /** The current authenticated user, if one is present. */
            this.currentUser = computedEager(() => store.state?.users?.me);

            /** The current selected account, if one is selected. */
            this.currentAccount = computedEager(
                () => store.state?.accounts?.account
            );

            /** The current selected organization, if one is selected. */
            this.currentOrganization = computedEager(
                () => store.state?.accounts?.organization
            );
        }

        // ==== STATUS ====
        {
            /**
             * Indicates if the view is currently loading content.
             */
            this.isLoading = computedEager(() => this.loading.value === true);
            /**
             * Indicates if the view is not currently loading content.
             */
            this.isIdling = computedEager(() => this.loading.value === false);
        }

        // ==== MODAL DIALOG ====
        {
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isAddModalOpen = computedEager(
                () => this.modal.value === 'addAccount'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isEditModalOpen = computedEager(
                () => this.modal.value === 'editAccount'
            );
            /**
             * Specifies if the specified modal is currently open.
             */
            this.isDeleteModalOpen = computedEager(
                () => this.modal.value === 'deleteAccount'
            );
        }
    }

    /**
     * Define the async properties.
     */
    defineAsync() {}
}

/**
 * @class
 * Submodule for the {@link AccountManager} composable.
 */
class Methods {
    /**
     * Create the submodule.
     * @param {Pick<AccountManager, 'store' | 'router' | 'grid' | 'constants' | 'events' | 'state'>} context
     */
    constructor(context) {
        this.setContext(context);
        this.defineHooks();
        this.defineTriggers();
        this.defineActions();
        this.defineUtilities();
    }

    /**
     * Assign the context.
     * @param {Pick<AccountManager, 'store' | 'router' | 'grid' | 'constants' | 'events' | 'state'>} context
     */
    setContext(context) {
        this.context = context;
    }

    /**
     * Define the event triggers.
     */
    defineHooks() {
        // ==== GRID ====
        {
            const { grid } = this.context;

            /** Register a callback invoked when a grid ready event is fired. */
            this.onGridReady = grid.onGridReady;
            /** Register a callback invoked when a column resize event is fired. */
            this.onColumnResized = grid.onColumnResized;
        }

        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Register a callback invoked at the start of an async content loading operation. */
            this.onLoadStarted = load.start.on;
            /** Register a callback invoked at the end of an async content loading operation. */
            this.onLoadStopped = load.stop.on;
        }

        // ==== ALERTS ====
        {
            const { alert } = this.context.events;

            /** Register a callback invoked after alert notifications are explicitly cleared. */
            this.onClearAlerts = alert.clear.on;
            /** Register a callback invoked after a successful alert notification is sent. */
            this.onAlertSuccess = alert.success.on;
            /** Register a callback invoked after a warning alert notification is sent. */
            this.onAlertWarning = alert.warning.on;
            /** Register a callback invoked after an error alert notification is sent. */
            this.onAlertError = alert.error.on;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Register a callback invoked when a modal is opened. */
            this.onModalOpened = modal.open.on;
            /** Register a callback invoked when a modal is closed. */
            this.onModalClosed = modal.close.on;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onAddButtonClicked = form.add.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onEditButtonClicked = form.edit.click.on;
            /** Register a callback invoked when the user clicks on the appropriate interface element. */
            this.onDeleteButtonClicked = form.delete.click.on;

            /** Register a callback invoked when the user cancels the specified form. */
            this.onAddCanceled = form.add.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onEditCanceled = form.edit.cancel.on;
            /** Register a callback invoked when the user cancels the specified form. */
            this.onDeleteCanceled = form.delete.cancel.on;

            /** Register a callback invoked when the user submits the specified form. */
            this.onAddSubmitted = form.add.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onEditSubmitted = form.edit.submit.on;
            /** Register a callback invoked when the user submits the specified form. */
            this.onDeleteSubmitted = form.delete.submit.on;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { events } = this.context;

            /** Register a callback invoked when the page must redirect to another location. */
            this.onRedirect = events.redirect.on;
            /** Register a callback invoked when the organizations have been refreshed. */
            this.onRefreshed = events.refreshed.on;
        }
    }

    /**
     * Define the event triggers.
     */
    defineTriggers() {
        // ==== STATUS ====
        {
            const { load } = this.context.events;

            /** Called at the start of an async content loading operation. */
            this.startLoading = load.start.trigger;
            /** Called at the completion of an async content loading operation. */
            this.stopLoading = load.stop.trigger;
        }

        // ==== ALERTS ====
        {
            const { AlertIDs } = this.context.constants;
            const { alert } = this.context.events;

            /** Collection of all alert ids. Useful as a default. */
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /** Clear the specified alerts. If nothing is passed, all domain alerts are cleared. */
            this.clearAlerts = (targets = ids) => alert.clear.trigger(targets);
            /** Sends a successful alert notification. */
            this.sendSuccessAlert = alert.success.trigger;
            /** Sends a warning alert notification. */
            this.sendWarningAlert = alert.warning.trigger;
            /** Sends an error alert notification. */
            this.sendErrorAlert = alert.error.trigger;
        }

        // ==== MODAL DIALOG ====
        {
            const { modal } = this.context.events;

            /** Open the modal specified by id. */
            this.openModal = modal.open.trigger;
            /** Close the modal specified by id. */
            this.closeModal = modal.close.trigger;
        }

        // ==== FORM ACTIONS ====
        {
            const { form } = this.context.events;

            /** Handle a user click on the specified interface element. */
            this.clickAddButton = form.add.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickEditButton = form.edit.click.trigger;
            /** Handle a user click on the specified interface element. */
            this.clickDeleteButton = form.delete.click.trigger;

            /** Triggered when the user cancels out of the specified form. */
            this.cancelAdd = form.add.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelEdit = form.edit.cancel.trigger;
            /** Triggered when the user cancels out of the specified form. */
            this.cancelDelete = form.delete.cancel.trigger;

            /** Triggered when the user submits the specified form. */
            this.submitAdd = form.add.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitEdit = form.edit.submit.trigger;
            /** Triggered when the user submits the specified form. */
            this.submitDelete = form.delete.submit.trigger;
        }

        // ==== MANAGER ACTIONS ====
        {
            const { events } = this.context;

            /** Send a redirect event with a `RouteLocationRaw` payload. */
            this.redirect = events.redirect.trigger;
            /** Send an updated payload once the organizations are refreshed. */
            this.refreshed = events.refreshed.trigger;
        }
    }

    /**
     * Define action methods.
     */
    defineActions() {
        // ==== FORM ACTIONS ====
        {
            const { currentOrganization } = this.context.state;

            /**
             * Get the sanitized request.
             *
             * @param {Omit<globalThis.Account.Target, 'id'>} target
             * @returns {globalThis.Account.Request.CreateResource}
             */
            this.getSanitizedCreateRequest = (target) => {
                // Get the properties from the target.
                const body = { ...target };

                // Sanitize the reminder field.
                if (!ReminderFrequency.isValue(body.reminderFrequency)) {
                    body.reminderFrequency = ReminderFrequency.Never;
                }

                const organizationId = /** @type {number} */ (
                    currentOrganization.value.id
                );

                // Create the payload.
                return {
                    name: body.name,
                    account_tree_level: body.treeLabels,
                    organization_id: organizationId,
                    timezone: body.timezone,
                    temp_scale: body.temperatureScale,
                    reminder: body.reminderFrequency,
                };
            };

            /**
             * Get the sanitized request.
             *
             * @param {Omit<globalThis.Account.Target, 'organizationId'>} target
             * @returns {globalThis.Account.Request.UpdateResource}
             */
            this.getSanitizedUpdateRequest = (target) => {
                // Get the properties from the target.
                const body = { ...target };

                // Sanitize the reminder field.
                if (!ReminderFrequency.isValue(body.reminderFrequency)) {
                    body.reminderFrequency = ReminderFrequency.Never;
                }

                // Create the payload.
                return {
                    id: body.id,
                    name: body.name,
                    account_tree_level: body.treeLabels,
                    timezone: body.timezone,
                    temp_scale: body.temperatureScale,
                    reminder: body.reminderFrequency,
                };
            };

            /**
             * Create a new resource on the server.
             * @param {Omit<globalThis.Account.Target, 'id'>} target
             */
            this.createResource = async (target) => {
                console.log(`[create::resource] <new Account()>`);
                // Get the sanitized request.
                const request = Object.freeze(
                    this.getSanitizedCreateRequest(target)
                );
                // Send the response.
                const response = await accounts.createAccount(request);
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (account) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'add-success',
                            content: 'Created 1 new account successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshIndex();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'add-error',
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Update an existing resource on the server.
             * @param {Omit<globalThis.Account.Target, 'organizationId'>} target
             */
            this.updateResource = async (target) => {
                console.log(`[update::resource] <${target.id}>`);
                // Get the sanitized request.
                const request = Object.freeze(
                    this.getSanitizedUpdateRequest(target)
                );
                // Send the response.
                const response = await accounts.updateAccountById(request);
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (organization) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'edit-success',
                            content: 'Updated 1 account successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshIndex();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'add-error',
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };

            /**
             * Update an existing resource on the server.
             * @param {Pick<globalThis.Account.Target, 'id'>} target
             */
            this.deleteResource = async (target) => {
                console.log(`[delete::resource] <${target.id}>`);
                // Send the response.
                const response = await accounts.deleteAccountById(target);
                // Conditionally execute based on if row was found.
                response.match({
                    Ok: (organization) => {
                        // Send success alert.
                        this.sendSuccessAlert({
                            id: 'delete-success',
                            content: 'Deleted 1 account successfully.',
                            dismissable: true,
                            ttl: 7000,
                        });
                        // Refresh the page.
                        this.refreshIndex();
                    },
                    Err: (e) => {
                        this.sendErrorAlert({
                            id: 'add-error',
                            content: e?.error.response.data.message,
                            dismissable: true,
                            ttl: 10000,
                        });
                    },
                });
            };
        }

        // ==== MANAGER ACTIONS ====
        {
            // Get the required submodules.
            const { state, constants } = this.context;

            /**
             * Refresh the resource index.
             */
            this.refreshIndex = async () => {
                // Clear related alerts.
                this.clearAlerts([
                    'refresh-success',
                    'refresh-warning',
                    'refresh-error',
                ]);
                try {
                    // Update the resource index for the table data.
                    this.startLoading('[refresh::accounts]');
                    // Get the current organization.
                    const id = state.currentOrganization.value?.id;
                    // Fetch collection of organizations.
                    const response =
                        await organizations.fetchOrganizationAccounts({ id });
                    // Handle the response.
                    if (response.isErr) throw response.error;
                    // Create row data models from resource index.
                    const index = response.isOk ? response.value : [];
                    // Trigger the index refresh.
                    this.refreshed({ index });
                    if (constants.IsDebug) {
                        // Send a success alert.
                        this.sendSuccessAlert({
                            id: 'refresh-success',
                            content: `Fetched ${index.length} account(s) successfully.`,
                            dismissable: false,
                            ttl: 5000,
                        });
                    }
                } catch (e) {
                    // Send error event.
                    this.handleClientError('refresh-error', e);
                } finally {
                    // Update the loading status.
                    this.stopLoading('[refresh::accounts]');
                }
            };
        }
    }

    /**
     * Define utility functions.
     */
    defineUtilities() {
        // ==== ERROR HANDLING ====
        {
            const { AlertIDs } = this.context.constants;
            const ids = /** @type {Array<keyof AlertIDs>} */ (
                Object.keys(AlertIDs)
            );

            /**
             * Handle the passed client error.
             * @param {keyof Constants['AlertIDs']} id The alert tag.
             * @param {Client.Error<Client.ErrorType>} err
             */
            this.handleClientError = (id, err) => {
                console.warn(`[${id}] ${err?.type}.`);
                this.sendErrorAlert({
                    id,
                    title: err?.title ?? 'Error',
                    messages: err?.messages ?? [
                        'An unexpected error occurred.',
                    ],
                    dismissable: true,
                    ttl: 10000,
                });
            };

            /**
             * Expire alerts after a scheduled delay.
             *
             * @param {(keyof Constants['AlertIDs'])[]} [targets]
             * @param {number} [delayInMilliseconds]
             */
            this.queueClearAlerts = async (
                targets = ids,
                delayInMilliseconds = 5000
            ) => {
                await promiseTimeout(delayInMilliseconds);
                this.clearAlerts(targets);
            };
        }

        // ==== UTILITY FUNCTIONS ====
        {
            /**
             * Compare two values.
             * @type {AgGrid.ColumnDef['comparator']}
             */
            this.compareByLowercase = (valueA, valueB, nodeA, nodeB) => {
                /** @type {string} */
                const valueALower = valueA.toLowerCase().trim();
                /** @type {string} */
                const valueBLower = valueB.toLowerCase().trim();
                // Return comparison value.
                return valueALower.localeCompare(valueBLower, 'en');
            };

            /**
             * Format the date value for the handled field.
             * @type {AgGrid.ValueFormatterFunc<string>}
             */
            this.formatDate = (params) => {
                // Get the value.
                const date = Maybe.of(params.value).map((dt) => new Date(dt));
                // Conditionally execute based on if row was found.
                const formatted = date.match({
                    Just: (dt) => {
                        return dt.toLocaleDateString('en-ca');
                    },
                    Nothing: () => 'No date provided.',
                });
                // Return the value.
                return formatted;
            };
        }

        // ==== GRID FUNCTIONS ====
        {
            // Get the required submodules.
            const { state } = this.context;

            /**
             * Get row data by its index in the array.
             * @param {number} index
             */
            this.findRowByIndex = (index) => {
                const position = Maybe.of(index).unwrapOr(-1);
                const row = state.rowData.value[position];
                return Maybe.of(row);
            };

            /**
             * Handle the click event from the edit button.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleEditButton = (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) =>
                        this.clickEditButton({ event, id: target.id }),
                    Nothing: () =>
                        this.handleClientError('edit-error', {
                            type: 'Error',
                            title: 'Cannot edit account.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Handle the click event from the delete button.
             *
             * @param {MouseEvent} event Click event.
             * @param {number} index Node index.
             */
            this.handleDeleteButton = (event, index) => {
                // Clear all existing alerts.
                this.clearAlerts();
                // Find the row by index.
                const row = this.findRowByIndex(index);
                // Conditionally execute based on if row was found.
                row.match({
                    Just: (target) =>
                        this.clickDeleteButton({ event, id: target.id }),
                    Nothing: () =>
                        this.handleClientError('delete-error', {
                            type: 'Error',
                            title: 'Cannot delete account.',
                            messages: [`No row exists at position ${index}.`],
                            timestamp: new Date(),
                        }),
                });
            };

            /**
             * Get keyed column definitions.
             * @returns {Readonly<Record<String, AgGrid.ColumnDef>>}
             */
            this.getColumnSchema = () => {
                return {
                    /** @type {AgGrid.ColumnDef} Table icons with button actions. */
                    icons: {
                        headerName: '',
                        field: 'actions',
                        cellRenderer: AccountManagerTableIcons,
                        lockPosition: true,
                        filter: false,
                        maxWidth: 120,
                        cellRendererParams: {
                            handleEdit: this.handleEditButton,
                            handleDelete: this.handleDeleteButton,
                        },
                    },
                    id: {
                        headerName: 'Account ID',
                        field: 'id',
                        maxWidth: 200,
                    },
                    name: {
                        headerName: 'Account Name',
                        field: 'name',
                        comparator: this.compareByLowercase,
                        minWidth: 130,
                        autoHeight: true,
                    },
                    lastUploadDate: {
                        headerName: 'Last Upload',
                        field: 'lastUploadAt',
                        valueFormatter: this.formatDate,
                        minWidth: 100,
                        sort: 'desc',
                    },
                };
            };

            /**
             * Get column definitions in ordered array.
             * @returns {AgGrid.ColumnDef[]}
             */
            this.getColumnDefs = () => {
                const schema = this.getColumnSchema();
                return [schema.icons, schema.name, schema.lastUploadDate];
            };
        }
    }
}

// <!-- MANAGER MODULE -->

/**
 * Orchestrates the organization management features.
 */
class AccountManager {
    /**
     * Instantiate a new manager.
     * @param {Parameters<AccountManager['boot']>[0]} [props] Composable parameters.
     */
    constructor(props = {}) {
        // Bind or inject services required by any submodules.
        this.boot(props);
        // Define the submodule interface.
        this.defineInterface();
        // Register the event listeners.
        this.registerEventListeners();
    }

    /**
     * Assign the context.
     * @param {Object} services Composable parameters.
     * @param {Vuex.Store<ECNBState>} [services.store] Optional store to provide. Will be instantiated if nothing is provided.
     * @param {Router.Instance} [services.router] Optional router to provide. Will be instantiated if nothing is provided.
     * @param {ReturnType<useAlerts>} [services.alerts] Alerts composable.
     * @param {ReturnType<useAgGridVue>} [services.grid] AgGrid composable.
     */
    boot({ store, router, alerts, grid }) {
        /** @type {Vuex.Store<ECNBState>} */
        this.store = store ?? useStore();

        /** @type {Router.Instance} */
        this.router = router ?? useRouter();

        /** @type {ReturnType<useAlerts>} */
        this.alerts = alerts ?? useAlerts();

        /** @type {ReturnType<useAgGridVue>} */
        this.grid = grid ?? useAgGridVue();
    }

    /**
     * Initializes the submodules needed to fulfill the manager's tasks.
     */
    defineInterface() {
        // Define submodules.
        this.constants = new Constants();
        this.events = new Events();
        this.state = new State(this);
        this.methods = new Methods(this);
    }

    /**
     * Register the internal event listener callbacks.
     */
    registerEventListeners() {
        // Provide access to state and methods.
        const { state, methods: _ } = this;

        // ==== STATUS ====
        {
            _.onLoadStarted((tag) => {
                console.time(tag);
                state.loading.value = true;
            });

            _.onLoadStopped((tag) => {
                state.loading.value = false;
                console.timeEnd(tag);
            });
        }

        // ==== ALERTS ====
        {
            const { clearAlert, createAlert, pushAlert } = this.alerts.methods;

            // Clear the specified alerts.
            _.onClearAlerts((ids) => {
                ids.forEach((id) => clearAlert(id));
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertSuccess((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'success',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertWarning((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'warning',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });

            // Create and raise the alert according to the given parameters.
            _.onAlertError((params) => {
                pushAlert(
                    createAlert({
                        ...params,
                        type: 'error',
                    })
                );

                // Schedule notification to dismiss itself after a set delay, if one is present.
                Maybe.of(params.ttl).match({
                    Just: (delay) => _.queueClearAlerts([params.id], delay),
                    Nothing: () => void 0,
                });
            });
        }

        // ==== MODAL DIALOG ====
        {
            // Sets the active modal.
            _.onModalOpened(({ id }) => {
                state.modal.value = id;
            });

            // Closes the active modal if it matches the given id.
            _.onModalClosed(({ id }) => {
                if (state.modal.value === id) {
                    state.modal.value = null;
                }
            });
        }

        // ==== FORM ACTIONS ====
        {
            // Sets the appropriate target and opens the appropriate dialog.
            _.onAddButtonClicked(({ event }) => {
                console.log(`[click::add]`, event);
                // Set the target and open the modal.
                state.targets.add.value = {
                    ...this.constants.DefaultAccountTarget,
                };
                _.openModal({ id: 'addAccount' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onEditButtonClicked(async ({ event, id }) => {
                console.log(`[click::edit] <${id}>`, event);
                // Request the organization target by id.
                const response = await accounts.fetchAccountById({
                    id,
                });

                // Error handling.
                if (response.isErr) {
                    const err = response.error;
                    _.sendErrorAlert({
                        id: 'edit-error',
                        title: err?.type,
                        content: err?.title,
                        messages: err?.messages,
                        dismissable: true,
                        ttl: 7000,
                    });
                    return;
                }

                // Unwrap the response to get the appropriate target.
                const target = response.isOk ? response.value : null;

                // Set the target and open the modal.
                state.targets.edit.value = target;
                _.openModal({ id: 'editAccount' });
            });

            // Sets the appropriate target and opens the appropriate dialog.
            _.onDeleteButtonClicked(async ({ event, id }) => {
                console.log(`[click::delete] <${id}>`, event);
                state.targets.delete.value = { id };
                _.openModal({ id: 'deleteAccount' });
            });

            // Closes the appropriate dialog and clears the target.
            _.onAddCanceled((event) => {
                console.log(`[cancel::add]`, event?.reason);
                _.closeModal({ id: 'addAccount' });
                state.targets.add.value = null;
            });

            // Closes the appropriate dialog and clears the target.
            _.onEditCanceled((event) => {
                console.log(`[cancel::edit]`, event?.reason);
                _.closeModal({ id: 'editAccount' });
                state.targets.edit.value = null;
            });

            // Closes the appropriate dialog and clears the target.
            _.onDeleteCanceled((event) => {
                console.log(`[cancel::delete]`, event?.reason);
                _.closeModal({ id: 'deleteAccount' });
                state.targets.delete.value = null;
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onAddSubmitted(async (event) => {
                console.log(`[submit:add] <${event?.target?.name}>`);
                try {
                    // Create new resource using the target data.
                    _.startLoading('submit:add');
                    _.closeModal({ id: 'addAccount' });
                    await _.createResource(event?.target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:add');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onEditSubmitted(async (event) => {
                console.log(`[submit:edit] <${event?.target?.id}>`);
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:edit');
                    _.closeModal({ id: 'editAccount' });
                    await _.updateResource(event?.target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:edit');
                }
            });

            // Submits the appropriate form and notifies the user of the outcome.
            _.onDeleteSubmitted(async () => {
                const target = state.targets.delete.value;
                console.log(`[submit:delete] <${target?.id}>`);
                try {
                    // Update existing resource using the target data.
                    _.startLoading('submit:delete');
                    _.closeModal({ id: 'deleteAccount' });
                    await _.deleteResource(target);
                } finally {
                    // Update the loading status.
                    _.stopLoading('submit:delete');
                }
            });
        }

        // ==== MANAGER ACTIONS ====
        {
            // Redirect the page using the specified location.
            _.onRedirect(({ to }) => {
                console.log(`[redirect]`, to);
                this.router.push(to);
            });

            // Refresh the table data.
            _.onRefreshed(({ index }) => {
                // Update the table with the row data models.
                const rows = index.map(Account.createRowModel);
                // Assign the row models.
                state.rowData.value = rows;
            });
        }
    }
}

// <!-- COMPOSABLE -->

/**
 * Composable feature for managing the admin view-model state.
 */
export const useAccountManager = () => {
    const manager = new AccountManager();
    return manager;
};

// <!-- DEFAULT -->
export default useAccountManager;
