// <!-- REACTIVITY API -->
import { ref, computed } from 'vue';

// <!-- COMPOSABLES -->
import { useStore } from 'vuex';
import { useNARAFeature } from '@/utils/features/';

/**
 * Create appropriate state and hooks for using tabs.
 * @param {{ commercial: SN.Tab[] | V.Ref<SN.Tab[]>, nara: SN.Tab[] | V.Ref<SN.Tab[]>}} defs Local state to use.
 */
export const useNARATabsIfEnabled = (defs) => {
    // Create reactive reference.
    const commercialTabs = ref(defs.commercial);
    const naraTabs = ref(defs.nara);

    const { isNARAEnabled } = useNARAFeature();

    const tabs = computed(() => {
        const areNARATabsVisible = isNARAEnabled.value;
        return [
            ...commercialTabs.value,
            ...(areNARATabsVisible ? naraTabs.value : []),
        ].sort((a, b) => {
            return (a?.position ?? Infinity) - (b?.position ?? Infinity);
        });
    });

    // Expose reactive state and hooks.
    return { tabs };
};

/**
 * Create appropriate state and hooks for using tabs.
 * @param {{ default: SN.Tab[] | V.Ref<SN.Tab[]>, commercial: SN.Tab[] | V.Ref<SN.Tab[]>, nara: SN.Tab[] | V.Ref<SN.Tab[]>, ipiadmin: SN.Tab[] | V.Ref<SN.Tab[]>}} defs Local state to use.
 */
export const useAdminTabs = (defs) => {
    // Create reactive reference.
    const defaultTabs = ref(defs.default);
    const commercialTabs = ref(defs.commercial);
    const ipiadminTabs = ref(defs.ipiadmin);
    const naraTabs = ref(defs.nara);

    const { isNARAEnabled } = useNARAFeature();

    const store = useStore();
    const user = computed(() => store.state.users.me);
    const userRole = computed(() => user.value.role);
    const isUserIPIAdmin = computed(() => {
        //TODO: Update with future IPI_Admin role.
        //if(userRole.value === 'ipiadmin') {
        if (true) {
            return true;
        } else {
            return false;
        }
    });

    const tabs = computed(() => {
        const areNARATabsVisible = isNARAEnabled.value;
        const areIPIAdminTabsVisible = isUserIPIAdmin.value;
        return [
            ...defaultTabs.value,
            ...(areNARATabsVisible ? naraTabs.value : []),
            ...(areIPIAdminTabsVisible ? ipiadminTabs.value : []),
            ...(!areNARATabsVisible ? commercialTabs.value : []),
        ].sort((a, b) => {
            return (a?.position ?? Infinity) - (b?.position ?? Infinity);
        });
    });

    // Expose reactive state and hooks.
    return { tabs };
};
