<template>
    <div
        v-if="!!source"
        :class="[isLoading ? 'animate-pulse' : '']"
    >
        <FormKit
            type="form"
            v-model="dirtyAccount"
            #default="context"
            :actions="false"
            :config="config"
        >
            <FormKit
                type="text"
                name="name"
                label="* Account Name"
                validation="required"
                :disabled="!isEditing"
                :innerClass="'$reset w-full border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500'"
            />
            <FormSection>
                <template #default>
                    <FormKit
                        type="select"
                        label="Time Zone"
                        name="timezone"
                        :options="timezoneOptions"
                        outerClass="col-span-4 sm:col-span-1 sm:min-w-max"
                        :validation-rules="{ timezone }"
                        :validation="[
                            ['required'],
                            ['*+not', 'placeholder'],
                            ['timezone'],
                        ]"
                        :disabled="!isEditing"
                    />
                    <FormKit
                        type="select"
                        label="Temperature Scale"
                        name="temperatureScale"
                        outerClass="col-start-1 col-span-4 sm:col-start-3 sm:col-span-1 sm:min-w-max"
                        :validation="[['required'], ['*+not', 'placeholder']]"
                        :disabled="!isEditing"
                        :options="temperatureOptions"
                    />
                </template>
            </FormSection>
            <HierarchyFormSection>
                <template #title>Manage Hierarchy</template>
                <template #description>
                    <p v-if="isNARAEnabled">
                        NARA uses consistent terminology and abbreviations for
                        choices in the four Location Hierarchy levels.
                        <span class="font-bold"
                            >Level 1- Building. Level 2-Administration</span
                        >
                        (AO-Archives Office. FRC-Records Center.
                        PLA-Presidential Library Archives. PLM-Presidential
                        Library Museum).
                        <span class="font-bold">Level 3-Space Type</span>
                        (ST-Storage. WR-Workroom. EX-Exhibition. RR-Research
                        Room).
                        <span class="font-bold">Level 4-Monitoring Site</span>
                        (space name/number or a custom identifier).
                    </p>
                    <div v-if="isNARADisabled">
                        Environmental management teams should work
                        collaboratively to develop consistent terminology for
                        each level of the location hierarchy to create a
                        structure that is intuitive to both collections and
                        facilities staff. For example, Second Floor, 2nd Fl.,
                        Two, or 2 can all refer to the same floor but will be
                        recognized as different floor levels in the hierarchy.
                        <span class="font-bold"
                            >Determine which terminology is preferred and use it
                            consistently.</span
                        >
                        <p class="pt-2">
                            On this page, labels can be assigned to each
                            hierarchy level, for example Site (level 1),
                            Building (level 2), Floor (level 3), and Room (level
                            4), to assist with naming conventions and
                            consistency. The labels assigned here will appear on
                            the Add Location page to guide editing of existing
                            locations and naming of new locations in this
                            account.
                        </p>
                    </div>
                </template>
                <template #default>
                    <FormKit
                        type="list"
                        name="treeLabels"
                    >
                        <FormKit
                            type="text"
                            label="Level 1"
                            name="site"
                            value="Site"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                            outerClass="pr-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 2"
                            name="building"
                            value="Building"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                            outerClass="pr-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 3"
                            name="floor"
                            value="Floor"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                            outerClass="pr-4"
                        />
                        <FormKit
                            type="text"
                            label="Level 4"
                            name="room"
                            value="Room"
                            :disabled="!isEditing"
                            validation="required:trim|length:1"
                        />
                    </FormKit>
                </template>
            </HierarchyFormSection>
            <p class="text-gray-400 text-sm">* indicates a required field</p>
            <LoadingWrapper
                v-if="isEditing"
                :isLoading="isSaving"
            >
                <div class="flex flex-row-reverse pt-5">
                    <ModalButton
                        v-if="isEditing"
                        theme="primary"
                        label="Save"
                        @click="onClickSave(context.state)"
                        :disabled="isLoading"
                    />
                    <ModalButton
                        theme="white"
                        label="Cancel"
                        @click="onClickCancel"
                    />
                </div>
            </LoadingWrapper>
        </FormKit>
        <div
            v-if="!!debug"
            class="mt-6"
        >
            <DebugFrame
                id="generic"
                :startHidden="frame.startHidden"
                :debug="frame.isEnabled"
                :data="frame.data"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        ref,
        reactive,
        onBeforeMount,
    } from 'vue';

    // <!-- UTILITIES -->
    import clone from 'just-clone';
    import { getLegacyTimezones } from '@/utils/timezone';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import HierarchyFormSection from '@/components/forms/sections/HierarchyFormSection.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';

    // <!-- TYPES -->
    /** @typedef {import('@formkit/core').FormKitNode} FormKitNode */

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useTimezoneOptions } from '~DataManager/hooks/useTimezoneOptions';
    import { useTemperatureOptions } from '~DataManager/hooks/useTemperatureOptions';
    import { useReminderOptions } from '~DataManager/hooks/useReminderOptions';
    import is from '@sindresorhus/is';
    import { useNARAFeature } from '@/utils/features/';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AccountFields',
        components: {
            FormSection,
            ModalButton,
            HierarchyFormSection,
            DebugFrame,
        },
        props: {
            source: {
                /** @type {Vue.PropType<Pick<globalThis.Account.Model, 'id' | 'name' | 'organizationId' | 'reminderFrequency' | 'treeLabels' | 'timezone' | 'temperatureScale'>>} */
                type: Object,
            },
            isEditing: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            isLoading: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
            debug: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false,
            },
        },
        emits: ['submit', 'cancel'],
        setup(props, { emit }) {
            // ==== STATE ====
            /** @type {Vue.Ref<Pick<globalThis.Account.Model, 'id' | 'name' | 'organizationId' | 'reminderFrequency' | 'treeLabels' | 'timezone' | 'temperatureScale'>>} */
            const dirtyAccount = ref(null);

            // DEFINE composable dependencies.
            const { isNARAEnabled, isNARADisabled } = useNARAFeature();

            const config = reactive({
                delay: 250,
                validationVisibility: 'blur',
            });

            // ==== EVENTS ====

            /** Clone the clean account. */
            const resetDirtyAccount = () => {
                /** @type {Pick<globalThis.Account.Model, 'id' | 'name' | 'organizationId' | 'reminderFrequency' | 'treeLabels' | 'timezone' | 'temperatureScale'>} */
                const instance = clone({ ...props.source });
                const useExisting =
                    !is.nullOrUndefined(instance?.treeLabels) &&
                    is.iterable(instance?.treeLabels);
                instance.treeLabels = useExisting
                    ? [...instance.treeLabels]
                    : ['Site', 'Building', 'Floor', 'Room'];
                dirtyAccount.value = instance;
            };

            /** Save the dirty account. */
            const onClickSave = async (state) => {
                config.validationVisibility = 'live';
                if (state.valid) {
                    emit('submit', { target: dirtyAccount.value });
                } else {
                    console.error('Form is not valid.');
                }
            };

            /** Clear the dirty account. */
            const onClickCancel = () => {
                resetDirtyAccount();
                emit('cancel', { reason: 'User canceled.' });
            };

            /* Data */
            const timezoneOptions = useTimezoneOptions();
            const legacyTimezones = getLegacyTimezones();
            const reminderOptions = useReminderOptions();
            const temperatureOptions = useTemperatureOptions();

            /**
             * Custom validation rule that ensures legacy timezones are not included.
             * @param {FormKitNode} node
             */
            const timezone = (node) => {
                const value = String(node?.value ?? 'placeholder');
                const invalid = new Set(['placeholder', ...legacyTimezones]);
                return !invalid.has(value);
            };

            // ==== DEBUG ====
            /**
             * Computed debug frame.
             */
            const frame = computed(() => {
                // Prepare data.
                const data = [
                    DebugObject.create(`Is Editing?`, {
                        isEditing: props.isEditing,
                    }),
                    DebugObject.create(`Is Loading?`, {
                        isLoading: props.isLoading,
                    }),
                    DebugObject.create(`Source Account Details`, {
                        source: props.source,
                    }),
                    DebugObject.create(`Dirty Account`, {
                        dirtyAccount: dirtyAccount.value,
                    }),
                ];
                // Return new frame instance.
                return useDebugFrame({
                    isEnabled: true,
                    startHidden: true,
                    data,
                });
            });

            // ==== LIFECYCLE ====
            onBeforeMount(() => {
                resetDirtyAccount();
            });

            // ==== EXPOSE ====
            return {
                isNARAEnabled,
                isNARADisabled,
                config,
                frame,
                dirtyAccount,
                timezoneOptions,
                legacyTimezones,
                reminderOptions,
                temperatureOptions,
                timezone,
                onClickSave,
                onClickCancel,
            };
        },
    });
</script>

<style lang="scss">
    .account-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
